@import '/styles/base';

.container {
  display: block;
}

.page_wrapper {
  display: flex;
}

.page_title {
  font-size: var(--h4-font-size);
  font-weight: 700;

  &_capitalize {
    text-transform: capitalize;
  }

  &_tail {
    margin-left: 3px;
  }
}

.banner_displayed_wrapper {
  margin: 15px 0px 10px 5px;
}

.banner_information_wrapper {
  display: block;
  min-height: 142px;
  border: solid 1px var(--ui-borders-color);
  border-radius: 12px;
}

.banner_information_title {
  width: 100%;
  border: none;
  border-radius: 12px;
  padding: 5px 20px 0px 20px;
  font-size: var(--h4-font-size);
  font-weight: 700;
  font-family: var(--primary-font-family);
  outline: none;
}

.banner_information_body {
  width: 100%;
  min-height: 108px;
  border: none;
  border-radius: 12px;
  padding: 5px 20px;
  font-size: var(--p-font-size);
  font-family: var(--primary-font-family);
  outline: none;
  resize: none;
}

.banner_preview_title {
  font-size: var(--subtitle-font-size);
  margin: 15px 0px 5px 0px;
  font-weight: 500;
}

.banner_wrapper {
  padding: 20px 30px;
  box-shadow: var(--dropdown-shadow-color);
  border: 1px solid var(--ui-borders-color);
  border-radius: 12px;
  background-color: var(--ui-banner-background);
}

.head {
  position: relative;
}

.title {
  font-size: var(--h4-font-size);
  font-weight: bold;
  line-height: 24px;
  color: var(--ui-banner-text-color);
  margin-bottom: 3px;
}

.text {
  color: var(--ui-banner-text-color);
}

.buttons_wrapper {
  display: flex;
  margin: 30px 0px 30px 0px;
  justify-content: flex-end;
  align-items: center;
}

@include forPhone {
  .container {
    display: block;
  }

  .orders_section {
    display: none;
  }

  .table_wrapper {
    margin: 10px 10px 0px 10px;
  }
}
