.root {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

.loader {
  color: var(--brand-color-primary);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 3px;
}

.loader::before,
.loader::after {
  position: absolute;
  left: 0;
  display: block;
  height: 100%;
  content: '';
}

.loader::before {
  width: 100%;
  opacity: 0.2;
  background: currentColor;
}

.loader::after {
  width: 0;
  animation-name: loading;
  animation-duration: 1160ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  background: currentColor;
}

@keyframes loading {
  10% {
    left: 0;
    width: 10%;
  }

  50% {
    left: 50%;
    width: 50%;
  }

  90% {
    left: 100%;
    width: 0;
  }
}
