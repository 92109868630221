@import '/styles/base';

.container {
  display: block;
}

.banners_section {
  display: flex;
  margin: 30px 10% 0px 30px;

  &_title {
    font-size: var(--h3-font-size);
    font-weight: bold;
  }
}

.list_wrapper {
  margin: 20px 10% 0px 20px;
}

@include forPhone {
  .container {
    display: block;
  }

  .banners_section {
    display: none;
  }

  .list_wrapper {
    margin: 10px 10px 0px 10px;
  }
}
