%picked {
  color: var(--font-color-muted);
  cursor: no-drop;
  background-color: var(--ui-background-picked);

  &:hover {
    box-shadow: unset;
  }
}

.button {
  --padding-vertical: 6px;
  --padding-horisontal: 18px;
  --font-size: 18px;
  --border-color: transparent;
  --background-color: transparent;
  --min-width: auto;
  --border-radius: 24px;
  font-family: var(--accent-font-family);
  font-size: var(--font-size);
  font-weight: 500;
  color: #fff;
  text-align: center;
  min-width: var(--min-width);
  padding: var(--padding-vertical) var(--padding-horisontal);
  cursor: pointer;
  transition: all var(--transition-duration);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  background: var(--background-color);

  &:hover {
    box-shadow: var(--box-shadow-hover);
  }

  &:disabled {
    @extend %picked;
  }
}

.rounded {
  --padding-horisontal: var(--padding-vertical);
  --border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: unset;

  & svg {
    width: 25px;
    height: 25px;
  }
}

span.button {
  font-size: var(--font-size);
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.small {
  --padding-vertical: 6px;
  --padding-horisontal: 10px;
  --font-size: 16px;
}

.medium {
  --font-size: 18px;
  --min-width: 120px;
}

.large {
  --padding-vertical: 10px;
  --padding-horisontal: 40px;
  --font-size: 20px;
  --min-width: 130px;
}

.fullWidth {
  width: 100%;
}

// colors
// 'default' | 'primary' | 'secondary' | 'ghost' | 'dark'
.primary {
  --background-color: var(--brand-color-primary);

  &:hover {
    --background-color: var(--brand-color-hover);
  }
}

.ghost {
  --background-color: transparent;

  &:disabled {
    --brand-color-primary: var(--ui-disabled-color);
    --border-color: var(--ui-disabled-color);
    background-color: var(--background-color);
  }

  &:hover {
    --background-color: transparent;
  }
  color: inherit;

  &:not(.outlined) {
    box-shadow: none;
  }

  &.outlined {
    --border-color: var(--ui-borders-color);

    &.rounded {
      --border-color: var(--brand-color-primary);
    }
  }
}

.dark {
  --background-color: var(--ui-accent-color);

  &:hover {
    --background-color: var(--ui-accent-color-hover);
  }
}

.secondary {
  color: var(--brand-color-hover);
}

//'default' | 'outlined' | 'picked' /* disabled */ | 'rounded';
.outlined {
  --border-color: currentColor;
}

.picked {
  color: var(--font-color-muted);
  background-color: var(--ui-background-picked);

  &:hover {
    box-shadow: unset;
  }
}

.underline {
  --border-radius: 0;
  color: initial;
  padding: 0;
  border-bottom: 1px solid var(--ui-accent-color);
  background-color: transparent;
}
