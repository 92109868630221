.base {
  position: relative;
  margin: 4px 0;
}

.root {
  position: absolute;
  z-index: -1;
  opacity: 0;

  & + label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &::before {
      display: inline-block;
      flex-grow: 0;
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin-right: 10px;
      content: '';
      transition:
        background-color var(--transition-duration),
        background-image var(--transition-duration),
        box-shadow var(--transition-duration);
      border: 1px solid var(--ui-borders-color);
      border-radius: 8px;
      background-color: var(--ui-background-neutral);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;
    }
  }

  &.rounded {
    & + label {
      &::before {
        border-radius: 100%;
      }
    }
  }

  &:checked {
    & + label {
      &::before {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--ui-accent-color);
        content: '\2713';
        color: var(--inversed-color);
      }
    }
  }

  /* disabled */
  &:disabled + label {
    &::before {
      background-color: var(--ui-background-picked);
    }
    cursor: revert;
  }

  &:focus {
    /* focused */
    & + label::before {
      box-shadow: var(--checkbox-box-shadow);
    }

    /* focused & not checked */
    &:not(:checked) + label::before {
      border-color: var(--ui-accent-color-hover);
    }
  }

  &:not(:disabled) {
    /* active */
    &:active + label::before {
      border-color: var(--ui-accent-color-hover);
      background-color: var(--ui-background-neutral);
    }

    /* hover */
    &:not(:checked) + label:hover::before {
      border-color: var(--font-color-accent);
    }
  }
}

.errorMessage {
  color: var(--brand-color-error);
  display: inline-block;
}

.groupWrapper {
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.inner {
  padding: 4px;
}
