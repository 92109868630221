@import '/styles/base';

.ownWrapper {
  position: relative;
  width: 310px;
  min-width: 250px;
  z-index: 1;

  .close {
    position: absolute;
    z-index: 8;
    top: 50%;
    right: 5%;
    transform: translate(0, -50%);
  }

  .calendarIcon {
    width: 20px;
    height: 22px;
    cursor: pointer;
  }
}

.mobileHead {
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: 60px;

  & p {
    font-weight: bold;
  }
}

.switcher,
.outputDate {
  p {
    font-size: var(--controls-font-size);
    line-height: 16px;
    color: var(--ui-accent-color-hover);
    text-align: center;
    margin: 0;
  }
}

.outputDate {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 58px;
  padding: 4px;
  cursor: pointer;
  transition: all var(--transition-duration);
  border: 1px solid var(--ui-borders-color);
  border-radius: 40px;
  background-color: var(--date-piker-background-color);

  .startDate {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }

  .endDate {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
  }

  .startDate,
  .endDate {
    p {
      margin-bottom: 4px;
    }
  }

  &.verticalLine::after {
    position: absolute;
    top: 50%;
    right: 50%;
    display: block;
    width: 1px;
    height: 25px;
    content: '';
    transform: translate(0, -50%);
    border-left: 1px solid var(--ui-borders-color);
    background-color: var(--ui-borders-color);
  }

  &.selectedDates {
    color: var(--font-color-primary);
  }

  .piked {
    padding: 5px 12px;
    border-radius: 28px;
    background-color: var(--ui-accent-color);

    p {
      color: var(--inversed-color);
    }

    span {
      color: var(--inversed-color);
      opacity: 0.6;
    }
  }

  .startDate.piked {
    width: 45%;
    margin-right: 5%;
  }

  .endDate.piked {
    width: 35%;
    margin-left: 2%;
  }
}

.outputDateActive {
  border: 1px solid var(--ui-step-default);
}

.switcher {
  display: flex;
  justify-content: flex-start;
  width: 240px;
  margin: 20px;
  padding: 4px;
  border: 1px solid var(--ui-accent-color);
  border-radius: 40px;

  .months {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin-left: 20px;
    padding: 10px;
    justify-self: flex-start;
    border-radius: 40px;

    &::after {
      position: absolute;
      top: 7px;
      left: -10px;
      display: block;
      width: 1px;
      height: 25px;
      content: '';
      border-left: 1px solid var(--ui-borders-color);
      background-color: var(--ui-borders-color);
    }
  }

  .calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding: 10px;
    border-radius: 40px;
  }

  :global {
    .active {
      color: var(--ui-banner-text-color);
      background-color: var(--font-color-primary);

      p {
        color: var(--ui-banner-text-color);
      }
    }
  }
}

.wrapper {
  overflow-y: hidden;
  width: 100%;
  max-width: 730px;
  margin: 20px;

  .mobileBody {
    overflow-y: auto;
  }

  .switchWrapper {
    display: flex;
    justify-content: center;
  }

  .showDate {
    display: flex;
  }

  .clearBlock {
    font-size: var(--subtitle-font-size);
    font-weight: 500;
    margin-top: 20px;
    padding-top: 20px;
    cursor: pointer;
    border-top: 1px solid var(--ui-borders-color);

    .clearLabel {
      border-bottom: 1px solid var(--font-color-primary);
    }
  }

  :global {
    .react-datepicker {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      border: none;
    }

    .react-datepicker__month-text {
      font-size: var(--controls-font-size);
      position: relative;
      width: 65px;
      height: 65px;
      margin: 3px 10px;
      padding-top: 24px;

      &:hover {
        font-weight: 400;
        color: var(--font-color-primary);
        border-radius: 50%;
        background-color: var(--ui-background-secondary);
      }
    }

    .months {
      :global {
        .react-datepicker__navigation {
          top: 65px;
        }

        .react-datepicker__navigation--next {
          right: 200px;
        }

        .react-datepicker__navigation--previous {
          left: 200px;
        }
      }
    }

    .react-datepicker__month--range-end,
    .react-datepicker__month--range-start {
      color: var(--ui-banner-text-color);
      background-color: var(--font-color-primary);
    }

    .react-datepicker__month--range-start,
    .react-datepicker__month-text--keyboard-selected {
      font-weight: 400;
      color: var(--ui-banner-text-color);
      border-radius: 50%;
      background-color: var(--font-color-primary);

      &:hover {
        color: var(--ui-banner-text-color);
        border-radius: 50%;
        background-color: var(--font-color-primary);
      }
    }

    .react-datepicker__month--in-range {
      color: var(--font-color-primary);
      border-radius: 50%;
      background-color: var(--ui-background-secondary);
    }

    .react-datepicker__input-container {
      position: relative;
      margin: 20px;

      input {
        color: var(--font-color-primary);
        width: 310px;
        padding: 25px 10px;
        border-color: var(--ui-accent-color);
        border-radius: 40px;
        outline: none;
      }

      .react-datepicker__close-icon {
        &::after {
          color: var(--font-color-muted);
          position: absolute;
          top: 18px;
          right: 50px;
          width: 30px;
          height: 30px;
          content: '';
          background-color: transparent;
          background-repeat: no-repeat;
          background-size: 28px;
        }
      }
    }

    .react-datepicker__month-container {
      //width: 50%;
      .react-datepicker__header {
        border-bottom: 0;
        background-color: transparent;
      }

      .react-datepicker__month {
        .react-datepicker__day {
          font-size: var(--controls-font-size);
          line-height: 38px;
          color: var(--font-color-primary);
          letter-spacing: 0.02em;
          width: 40px;
          margin: 1px 0 0;
          padding: 2px 0;
          border-radius: 0;

          &:not([aria-disabled='true']):hover {
            color: var(--ui-banner-text-color);
            border-radius: 50%;
            background-color: var(--font-color-primary);
          }
          &.react-datepicker__day--outside-month {
            background-color: transparent;
          }
        }

        .react-datepicker__day--disabled {
          color: var(--ui-disabled-color);
        }

        .react-datepicker__day--weekend {
          box-shadow: none;
        }

        .react-datepicker__day--keyboard-selected:not(
            .react-datepicker__day--outside-month
          ),
        .react-datepicker__day--selected:not(
            .react-datepicker__day--outside-month
          ),
        .react-datepicker__day--range-start:not(
            .react-datepicker__day--outside-month
          ),
        .react-datepicker__day--range-end:not(
            .react-datepicker__day--outside-month
          ) {
          font-weight: 400;
          color: var(--ui-banner-text-color);
          margin: 0;
          border-radius: 50%;
          background-color: var(--font-color-primary) !important;
        }

        .react-datepicker__day--highlighted:not(
            .react-datepicker__day--outside-month
          ) {
          position: relative;
          color: var(--ui-banner-text-color);
          background-color: var(--brand-color-primary) !important;
          border-radius: 0 !important;
          &:hover {
            color: var(--ui-banner-text-color);
            background-color: var(--brand-color-primary) !important;
            border-radius: 0 !important;
          }
          &::after {
            content: 'EVENT';
            position: absolute;
            top: 15px;
            left: 50%;
            transform: translateX(-50%);
            height: 40px;
            width: 40px;
            font-size: 8px;
          }
          &.react-datepicker__day--range-start {
            border: 1px solid var(--font-color-primary);
          }
          &.react-datepicker__day--range-end {
            border: 1px solid var(--font-color-primary);
          }
        }

        .react-datepicker__day--in-selecting-range {
          background-color: var(--ui-background-secondary);
        }

        .react-datepicker__day--in-range {
          background-color: var(--ui-background-secondary);
        }
      }
    }
  }
}

@include forDesktop {
  .wrapper {
    max-height: 520px;
  }
}

@include forPhone {
  .wrapper {
    margin: 0px;
  }

  .mobileDualDatePicker {
    & > :first-child > :first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
