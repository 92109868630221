@import 'styles/base';

.headingContent {
  position: relative;
  display: flex;
  align-items: center;
  margin: 30px 0;
}

.headingImgDesk {
  margin-top: 30px;

  img > {
    border-radius: 20px !important;
  }
}

.headingImgMobile {
  display: none;
  margin-top: 20px;
}

.logoMobile {
  display: none;
}

.logoDesk {
  margin-right: 30px;
}

.headingTitle {
  line-height: 100%;
  margin: 0;
}

.headingContent::after {
  @include line;
  bottom: -5px;
}

@include forPhone {
  .headingContent::after {
    display: none;
  }

  .headingContent {
    margin: 20px 0;
  }

  .headingImgDesk {
    margin-top: 20px;
  }

  .headingTitle {
    font-size: 36px;
  }

  .logoMobile {
    margin-right: 10px;
  }
}

@include small {
  .headingImgDesk,
  .logoDesk {
    display: none;
  }

  .headingImgMobile,
  .logoMobile {
    display: block;
  }
}

.imageWrapper {
  display: flex;
  overflow: hidden;
  border-radius: 15px;
}
