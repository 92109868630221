$horizontal-padding: 20px;

/* media query */
@mixin large {
  @media (max-width: 899px) {
    @content;
  }
}

@mixin medium {
  @media (max-width: 799px) {
    @content;
  }
}

@mixin forPhone {
  @media (max-width: 699px) {
    @content;
  }
}

@mixin small {
  @media (max-width: 399px) {
    @content;
  }
}

@mixin forDesktop {
  @media (min-width: 699px) {
    @content;
  }
}

@mixin forNotebook {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin input {
  font-family: var(--primary-font-family);
  font-size: var(--p-font-size);
  color: var(--font-color-primary);
  text-overflow: ellipsis;
  border: unset;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--font-color-muted);
  }
}

@mixin line {
  position: absolute;
  width: 100%;
  height: 1px;
  content: '';
  background-color: var(--ui-borders-color);
}

%error-shared {
  --ui-borders-color: var(--brand-color-error);
  --font-color-muted: var(--brand-color-error);
}

%error-message-shared {
  color: var(--brand-color-error);
  display: inline-block;
  padding: 0 $horizontal-padding;
}

$buy-card-vertical-padding: 20px;
